<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
	<div class="card">
		<p-toolbar styleClass="p-mb-4">
			<div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						<label for="filter_serviceIstances">Service istances</label>
						<br />
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
							placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
							[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
						</p-dropdown>
					</div>
					<div>
						<label for="filter_document_status">Status documents</label>
						<p-dropdown [options]="documentsStatus" #dropdown_documentsStatus [showClear]="true" [style]="{'min-width':'100%'}"
							placeholder="Select status" (onChange)="getList(null)" optionLabel="label" name="document_status" optionValue="value">
						</p-dropdown>
					</div>
				</div>
			</div>
			<div class="p-toolbar-group-right">
				<button pButton label="Create Document" *ngIf="userHasPermission('CAN_ADD_DOCUMENT')" (click)="createDocument()" class="p-button-oris"></button>
			</div>
		</p-toolbar>
		<p-table #dtDocument [value]="documentList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
			[globalFilterFields]="['fileName', 'status', 'description', 'notes', 'creationTime', 'document_category']" (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
			[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="documentListLoading">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
					Document List
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtDocument.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="fileName">
						<div class="p-d-flex p-jc-between p-ai-center">
							File Name
							<p-sortIcon field="fileName"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="status">
						<div class="p-d-flex p-jc-between p-ai-center">
							Status
							<p-sortIcon field="status"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="description">
						<div class="p-d-flex p-jc-between p-ai-center">
							Description
							<p-sortIcon field="description"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="notes">
						<div class="p-d-flex p-jc-between p-ai-center">
							Notes
							<p-sortIcon field="notes"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="creationTime">
						<div class="p-d-flex p-jc-between p-ai-center">
							Creation time
							<p-sortIcon field="creationTime"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="document_category">
						<div class="p-d-flex p-jc-between p-ai-center">
							Document Category
							<p-sortIcon field="document_category"></p-sortIcon>
						</div>
					</th>
					<th style="width: 8rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-document let-expanded="expanded">
				<tr class="p-selectable-row">
					<td>
						<a href="/assets/documents/{{document.fileName}}" target="_blank">{{document.fileName}}</a>
					</td>
					<td>
						{{document.status}}
					</td>
					<td>
						{{document.description}}
					</td>
					<td>
						{{document.notes}}
					</td>
					<td>
						{{document.creationTime | date: 'dd/MM/yyyy hh:mm:ss'}}
					</td>
					<td>
						{{document.documentsCategoryId}}
					</td>
					<td>
						<button pButton type="button" title="Edit" *ngIf="userHasPermission('CAN_EDIT_DOCUMENT')" icon="pi pi-pencil"
							class="p-button-rounded p-button-text" (click)="editDocument(document.id, document.fileName)"></button>
						<button pButton type="button" title="Delete" *ngIf="userHasPermission('CAN_DELETE_DOCUMENT_BY_ID')" class="p-button-text" icon="pi pi-trash"
							(click)="deleteDocument(document.id, document.fileName)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No records found</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<form #createDocumentForm="ngForm" (ngSubmit)="createDocumentForm.form.valid && saveDocument()">
	<p-dialog header="New Document" [(visible)]="documentCreateModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6" *ngIf="!userHasPermission('IS_CLIENT')">
				<label for="newDocument_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="document_new.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serviceInstances" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createDocumentForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="newDocument_father">Document Category</label>
				<p-dropdown [options]="serviceDocumentsCategoriesList" [(ngModel)]="document_new.documentsCategoryId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select father category" optionLabel="name"
					name="father" [baseZIndex]="10001" required optionValue="id" #father='ngModel'>
				</p-dropdown>
				<div *ngIf="createDocumentForm.submitted && father.invalid" class="error-login-info">
					<small *ngIf="father.errors?.required" class="p-error">Select document category</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="newDocument_father">Status</label>
				<p-dropdown [options]="documentsStatus" [readonly]="isFieldReadOnly()" [(ngModel)]="document_new.status"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select status" optionLabel="label"
					name="status" [baseZIndex]="10001" optionValue="value" #status='ngModel'>
				</p-dropdown>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="document_new_description">Description</label>
				<textarea type="text" pInputText id="document_new_description" rows="3" name="document_new_description"
					[(ngModel)]="document_new.description" #description='ngModel'></textarea>
			</div>
			<div class="p-col-12">
				<label for="document_new_notes">Notes</label>
				<textarea type="text" pInputText id="document_new_notes"  rows="3" name="document_new_notes"
					[(ngModel)]="document_new.notes" #description='ngModel'></textarea>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="newDocument_file">Upload File</label>
				<p-fileUpload #document name="document[]" [auto]="true" (uploadHandler)="onAutoUpload($event, document)"
					[customUpload]="true" [styleClass]="{width:'400px'}" chooseLabel="Upload File" fileLimit="1">
					<ng-template pTemplate="file"></ng-template>
					<ng-template pTemplate="content">
						<div *ngIf="documentFile.length">
							<div class="p-fluid p-formgrid p-grid container-center">
								<div *ngFor="let file of documentFile">
									<div class="p-field p-col-12">
										<div class="container-center">
											<img class="img-documents" src="assets/images/icons/doc-image.svg">
										</div>
										<br />
										<input type="text" pInputText value="{{file.name}}" [disabled]="true">
										<br />
										<div class="button-file-center">
											<button pButton pRipple icon="pi pi-trash" type="button" title="Delete"
												(click)="onRemove(file, document)"
												class="p-button-rounded p-button-text p-mr-2 pad-button-file"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</p-fileUpload>
			</div>
		</div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateDocument()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editDocumentForm="ngForm" (ngSubmit)="editDocumentForm.form.valid && updateDocument()">
	<p-dialog header="New Document" [(visible)]="documentEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6" *ngIf="!userHasPermission('IS_CLIENT')">
				<label for="document_edit_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="document_edit.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serviceInstances" [baseZIndex]="10001" required optionValue="id" #serviceInstanceId='ngModel'>
				</p-dropdown>
				<div *ngIf="editDocumentForm.submitted && serviceInstanceId.invalid" class="error-login-info">
					<small *ngIf="serviceInstanceId.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="document_edit_father">Document Category</label>
				<p-dropdown [options]="serviceDocumentsCategoriesList" [(ngModel)]="document_edit.documentsCategoryId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select father category" optionLabel="name"
					name="documentsCategoryId" [baseZIndex]="10001" optionValue="id" #documentsCategoryId='ngModel'>
				</p-dropdown>
			</div>
			<div class="p-col-3">
				<label for="newDocument_father">Status</label>
				<p-dropdown [options]="documentsStatus" [readonly]="isFieldReadOnly()" [(ngModel)]="document_edit.status"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select status" optionLabel="label"
					name="status" [baseZIndex]="10001" optionValue="value" #status='ngModel'>
				</p-dropdown>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="document_edit_description">Description</label>
				<textarea type="text" pInputText id="document_edit_description" rows="3" name="document_edit_description"
					[(ngModel)]="document_edit.description" #description='ngModel'></textarea>
			</div>
			<div class="p-col-12">
				<label for="document_edit_notes">Notes</label>
				<textarea type="text" pInputText id="document_edit_notes"  rows="3" name="document_edit_notes"
					[(ngModel)]="document_edit.notes" #description='ngModel'></textarea>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="newDocument_file">Uploaded file name: </label>
				<div style="background-color: #f2f2f5;padding:10px;border-radius:8px;margin-top:10px;">{{document_edit.fileName}}</div>
			</div>
		</div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditDocument()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>