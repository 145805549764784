<div class="p-col-12">
    <div *ngIf="productExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left">
                <div class="p-d-flex p-flex-column p-flex-md-row">
                    <div style="margin-right: 2rem;">
                        <label for="filter_serviceIstances">Service istances</label>
                        <br />
                        <p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
                            placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
                            [style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
                        </p-dropdown>
                    </div>
                    <div style="margin-right: 2rem;">
                        <label for="filter_collectionPlan">Collection plans</label>
                        <br />
                        <p-dropdown [options]="collectionPlanFilter" [(ngModel)]="collectionPlanId" #dropdown_collectionPlans placeholder="Select collection plan"
                            (onChange)="getList(null)" [showClear]="true" [style]="{'min-width':'100%'}" optionLabel="name"
                            name="collectionPlan" optionValue="id">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </p-toolbar>
        <p-toolbar styleClass="p-d-block p-mb-4" *ngIf="collectionPlanData !== undefined">
            <h3 class="p-d-block p-col-12" style="color: #222;">Collection Plan</h3>
            <div class="p-d-bloc kp-col-12" style="background-color: #ac965f;color:#fff;border-radius: 5px;">
                <div class="p-formgroup-inline">
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Name:</b> {{collectionPlanData.header.name}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Transaction Id:</b> {{collectionPlanData.header.transactionId}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Project:</b> {{collectionPlanData.header.project}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Season:</b> {{collectionPlanData.header.season}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Collection:</b> {{collectionPlanData.header.collection}}</div>
                </div>
                <div class="p-formgroup-inline" style="border-top:1px solid #948150;">
                    <div class="p-col-12"><b>User:</b> {{collectionPlanData.header.user}}</div>
                </div>
            </div>
        </p-toolbar>
        <p-table #dtCollectionPlanProduct [value]="productList" dataKey="id" styleClass="p-datatable-oris"
            [rowHover]="true" [globalFilterFields]="['name', 'alkenium_number', 'customer', 'service_instance']"
            (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)"
            [paginator]="true" [filterDelay]="0" [loading]="productListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection Plan Products List
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"
                            (input)="dtCollectionPlanProduct.filterGlobal($event.target.value, 'contains')"
                            placeholder="Global Search" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Code
                            <p-sortIcon field="code"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="product_name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Product Name
                            <p-sortIcon field="product_name"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="product_category">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Product Category
                            <p-sortIcon field="product_category"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="family">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Family
                            <p-sortIcon field="family"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="line">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Line
                            <p-sortIcon field="line"></p-sortIcon>
                        </div>
                    </th>
                    <th style="width: 8rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{product.code}}
                    </td>
                    <td>
                        {{product.productName}}
                    </td>
                    <td>
                        {{product.productCategory}}
                    </td>
                    <td>
                        {{product.family}}
                    </td>
                    <td>
                        {{product.line}}
                    </td>
                    <td>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_PRODUCT_LIST')" title="Show details"
                            icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                            (click)="showProduct(product.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_PRODUCT')" title="Export Excel"
                            class="p-button-text" icon="pi pi-file-excel"
                            (click)="exportProduct(product.id, product, exportFileType.EXCEL)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_PRODUCT')" title="Export Pdf"
                            class="p-button-text" icon="pi pi-file-pdf" (click)="exportProduct(product.id, product, exportFileType.PDF)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>