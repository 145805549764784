import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { CustomerService } from "../customers/service/customerservice";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { CollectionPlan } from "./domain/collectionPlan";
import { CollectionPlanService } from "./service/collectionPlan.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";


@Component({
    templateUrl: "./collectionPlan.component.html",
    styleUrls: ["./collectionPlan.component.scss"],
})


export class CollectionPlanComponent {
    private token: string;
    public collectionPlanList: CollectionPlan[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public collectionPlanListLoading: boolean = true;
    public collectionPlanExportLoading: boolean = false; 
    public exportFileType = ExportFileType;
    public warningModal: boolean;
    public totalRecords: number;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 5;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private collectionPlanService: CollectionPlanService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plans', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setServiceIstanceFilter();
        context.setClientsDropdown();
        context.setServiceInstancesDropdown();
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    private setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    public getExtensionFile(typeExport: ExportFileType) {
        let context = this;
        if (typeExport === context.exportFileType.EXCEL) {
            return "xlsx";
        } else if (typeExport === context.exportFileType.PDF) {
            return "pdf";
        } else {
            return "xlsx";
        }
    }

    public exportFile(content: string, name: string, typeExport: ExportFileType) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName = "PIANO COLLEZIONE ALKENIUM " + name.replace(/[^a-zA-Z0-9 ]/g, '') + "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public getList(event:LazyLoadEvent) {
        let context = this;
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.collectionPlanListLoading = true;
        context.collectionPlanService.getCollectionPlanList(context.token, context.event_lazy_local, serviceInstanceId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;

                        context.collectionPlanList = slice.map((collectionPlan) => {
                            return {
                                id: collectionPlan.id,
                                serviceInstanceId: collectionPlan.serviceInstanceId,
                                header: collectionPlan.header,
                                details: collectionPlan.details,
                                versionNumber: collectionPlan.versionNumber
                            }
                        });
                        context.collectionPlanListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public showCollectionPlan(id: string) {
        this.router.navigate(["collectionplans/detail/" + id]);
    }

    public showCollectionPlanProducts(id: string) {
        this.router.navigate(["collectionplans/" + id + "/products"]);
    }

    public exportCollectionPlan(id: string, name: string, typeExport: ExportFileType) {
        let context = this;
        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;
        context.collectionPlanExportLoading = true;

        context.collectionPlanService.exportCollectionPlan(context.token, serviceInstanceId, id, typeExport).pipe(
                tap((response) => {
                    if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined'  && response.data.content !== '') {
                        context.exportFile(response.data.content, name, typeExport);
                        context.messageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: response.message ?? "Exported the file successfully"
                        });
                    } else {
                        context.messageService.add({
                          severity: "error",
                          summary: "Error",
                          detail: "Could not export the file",
                        });
                        return of();
                    }
                }),catchError((error) => {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
                    return of();
                }),finalize(() => {
                    context.collectionPlanExportLoading = false;
            })).subscribe();
    }
}