<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
	<div class="card">
		<p-toolbar styleClass="p-mb-4">
			<div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						<label for="filter_serviceIstances">Service istances</label>
						<br />
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
							placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
							[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
						</p-dropdown>
					</div>
				</div>
			</div>
			<div class="p-toolbar-group-right">
				<button pButton label="Create Document Category" *ngIf="userHasPermission('CAN_ADD_DOCUMENT_CATEGORY')" (click)="createDocumentCategory()" class="p-button-oris"></button>
			</div>
		</p-toolbar>
		<p-table #dtDocument [value]="documentList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
			[globalFilterFields]="['name', 'description', 'father_category']" (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
			[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="documentListLoading">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
					Document Categories
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtDocument.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">
						<div class="p-d-flex p-jc-between p-ai-center">
							Name
							<p-sortIcon field="name"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="description">
						<div class="p-d-flex p-jc-between p-ai-center">
							Description
							<p-sortIcon field="description"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="father">
						<div class="p-d-flex p-jc-between p-ai-center">
							Father Category
							<p-sortIcon field="father"></p-sortIcon>
						</div>
					</th>
					<th style="width: 8rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-document let-expanded="expanded" >

				<tr class="p-selectable-row" *ngIf="bodyLoaded">
					<td>
						{{document.name}}
					</td>
					<td>
						{{document.description}}
					</td>
					<td>
						{{document.father}}
					</td>
					<td>
						<button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_DOCUMENT_CATEGORY')" title="Edit" icon="pi pi-pencil"
							class="p-button-rounded p-button-text" (click)="editDocument(document.id)"></button>
						<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_DOCUMENT_CATEGORY_BY_ID')"  title="Delete" class="p-button-text" icon="pi pi-trash"
							(click)="deleteDocumentCategory(document.id, document.name)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No records found</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<form #createDocumentCategoryForm="ngForm" (ngSubmit)="createDocumentCategoryForm.form.valid && saveDocument()">
	<p-dialog header="New Document Category" [(visible)]="documentCategoryCreateModal" [modal]="true"
		[style]="{width: '800px'}" styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="newDocument_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="documentCategoryNew.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name" required
					name="serviceInstances" [baseZIndex]="10001" optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createDocumentCategoryForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
			<div class="p-col-6">
				<label for="newDocument_father">Father Category</label>
				<p-dropdown [options]="serviceDocumentsCategoriesList" [(ngModel)]="documentCategoryNew.father"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select father category" optionLabel="name"
					name="father" [baseZIndex]="10001" optionValue="id" #father='ngModel'>
				</p-dropdown>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="customerEdit_name">Name</label>
				<input type="text" pInputText id="customerEdit_name" name="customerEdit_name"
					[(ngModel)]="documentCategoryNew.name" required #name='ngModel'>
				<div *ngIf="createDocumentCategoryForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Insert name</small>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="customerEdit_description">Description</label>
				<textarea pInputText id="customerEdit_description" rows="4" name="customerEdit_description"
				[(ngModel)]="documentCategoryNew.description" required #description='ngModel'></textarea>
				<div *ngIf="createDocumentCategoryForm.submitted && description.invalid" class="error-login-info">
					<small *ngIf="description.errors?.required" class="p-error">Insert description</small>
				</div>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateDocumentCategory()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editDocumentCategoryForm="ngForm" (ngSubmit)="editDocumentCategoryForm.form.valid && updateDocumentCategory()">
	<p-dialog header="Edit Document Category" [(visible)]="documentCategoryEditModal" [modal]="true"
		[style]="{width: '800px'}" styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="newDocument_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="documentCategoryEdit.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serviceInstances" [baseZIndex]="10001" required optionValue="id" #serviceInstanceId='ngModel'>
				</p-dropdown>
				<div *ngIf="editDocumentCategoryForm.submitted && serviceInstanceId.invalid" class="error-login-info">
					<small *ngIf="serviceInstanceId.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
			<div class="p-col-6">
				<label for="newDocument_fatherCategory">Father Category</label>
				<p-dropdown [options]="serviceDocumentsCategoriesList" [(ngModel)]="documentCategoryEdit.father"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select Father Category" optionLabel="name"
					name="serviceFatherCategory" [baseZIndex]="10001" optionValue="id" #edit_father='ngModel'>
				</p-dropdown>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="documentCategoryEdit_name">Name</label>
				<input type="text" pInputText id="documentCategoryEdit_name" name="documentCategoryEdit_name"
					[(ngModel)]="documentCategoryEdit.name" #edit_name='ngModel'>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="documentCategoryEdit_description">Description</label>
				<textarea  pInputText id="documentCategoryEdit_description" rows="4" name="documentCategoryEdit_description"
				[(ngModel)]="documentCategoryEdit.description" #edit_description='ngModel'></textarea>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditDocumentCategory()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>