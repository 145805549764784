<div class="layout-dashboard">
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
           <h2 class="white" style="font-weight:100;font-size:36px;">Categories</h2>
        </div>
    </div>
    <div class="p-grid">
        <ng-container *ngFor="let item of categories; let i = index;">
            <div class="p-col-12 p-md-4">
                <div class="card ">
                    <h3 class="black">{{item.name}}</h3>
                    <p>{{item.description}} </p>
                    <button pButton type="button" (click)="openDocumentsList(item.id)" label="View Documents" [disabled]="item.documentsCount == 0"></button> <!--disabled="disabled"-->
                </div>
            </div>
        </ng-container>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
           <h2 class="white" style="font-weight:100;font-size:36px;margin-top: 20px;">Projects Status <span style="color: #fff; float:right; font-size: 14px; text-transform: uppercase;">Last 5 projects <a href="/#/projects" style="color: #fff;font-weight:bold;">see all</a></span></h2>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-12" *ngIf="projects.length > 0">
            <div class="card" *ngFor="let projectItem of projects">
                <div class="p-grid">
                    <div class="p-col-12 p-md-3">
                        <h3 style="margin-top:20px;">{{projectItem.name}}</h3>
                        <p>Number: {{projectItem.alkeniumNumber}}</p>
                        <p><b>Start Date:</b>{{projectItem.startTime}}</p>
                        <p><b>End Date:</b>{{projectItem.endTime}}</p>
                       
                    </div>
                    <div class="p-col-12 p-md-9">
                        <p-timeline [value]="events2" layout="horizontal" align="top">
                            <ng-template pTemplate="marker" let-event>
                                <span class="custom-marker shadow-2" [style.backgroundColor]="event.color">
                                    <i [ngClass]="event.icon"></i>
                                </span>
                            </ng-template>
                            <ng-template pTemplate="content" let-event>
                                <p-card [header]="event.status" [subheader]="event.date">
                                    <h4>{{event.status}}</h4>
                                    <p>{{event.description}}</p>
                                </p-card>
                            </ng-template> 
                            <ng-template pTemplate="opposite" let-event></ng-template>
                        </p-timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
