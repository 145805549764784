import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { User } from './domain/user';
import { UserService } from './service/userservice';
import { NgForm } from '@angular/forms';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { Group } from '../groups/domain/group';
import { GroupService } from '../groups/service/groupservice';
import { Dropdown } from 'primeng/dropdown';
import { TreenNodes } from "src/app/utilities/treenodes";
import { TreeNode } from 'primeng/api';
import { Permission } from '../permissions/domain/permission';
import { PermissionService } from '../permissions/service/permissionservice';
import { AppGlobals } from 'src/app/app.globals';
import { BaseDropdown } from 'src/app/core/base/models/baselist';
import { RoleToSelect } from 'src/app/enum/roleToSelect.enum';
import Translate from 'src/app/utilities/translate';
import { Customer } from '../customers/domain/customer';
import { Supplier } from '../suppliers/domain/supplier';
import { SupplierService } from '../suppliers/service/supplierservice';
import { CustomerService } from '../customers/service/customerservice';
@Component({
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})


export class UserComponent {
    private token: string;
    public user_new: User = {};
    public user_edit: User = {};
    public usersList: User[];
    public serviceInstanceList: ServiceInstance[];
    public customerList: Customer[];
    public supplierList: Supplier[]
    public groupList: Group[];

    public roleselected: number;

    public permissionsNodes: TreeNode[];
    public permissionsNodesSelected: TreeNode[];
    public permissionsNodesUnselected: TreeNode[];

    public permissionsList: Permission[];
    public permissionsUserList: Permission[];
    public permissionstrings: string[];

    public roleToSelect: BaseDropdown[];

    public userPermissionId: string;

    public userCreateModal: boolean = false;
    public userEditModal: boolean = false;
    public usersListLoading: boolean = true;
    public totalRecords: number;
    public disable_group: boolean = true;
    public permissionModal: boolean = false;
    public treenodes: TreenNodes;

    public translate: Translate;

    event_lazy_local: LazyLoadEvent;

    @ViewChild("createUserForm")
    createUserForm: NgForm;
    @ViewChild("editUserForm")
    editUserForm: NgForm;
    @ViewChild("service_instance")
    service_instance: Dropdown;

    @ViewChild("dropdown_roletoselect")
    dropdown_roletoselect: Dropdown;
    @ViewChild("dropdown_suppliers")
    dropdown_suppliers: Dropdown;
    @ViewChild("dropdown_customers")
    dropdown_customers: Dropdown;
    @ViewChild("edit_dropdown_roletoselect")
    edit_dropdown_roletoselect: Dropdown;

    itemsPerPage: number = 5;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private userService: UserService,
        private messageService: MessageService,
        private serviceInstanceService: InstanceService,
        private customerService: CustomerService,
        private supplierService:SupplierService,
        private groupService: GroupService,
        private permissionService: PermissionService,
        private confirmationService: ConfirmationService) {
            
        this.breadcrumbService.setItems([
            AppGlobals.setBreadCrumb('Users', '/users', AppGlobals.userHasPermission("IS_CLIENT"))
        ]);
        this.token = sessionStorage.getItem('alkenium_Token').toString();
        this.event_lazy_local = {} as LazyLoadEvent;
        this.treenodes = new TreenNodes();
        this.translate = new Translate();
        
        this.permissionsNodesSelected = [] as TreeNode[];
        this.permissionsNodesUnselected = [] as TreeNode[];

        this.setServiceInstancesDropdown();
        this.setGroupDropdown();
        this.setCustomersDropdown();
        this.setSuppliersDropdown();
        this.roleToSelect = [] as BaseDropdown[];
        this.setRoleToSelect();
    }

    checkNode(nodes: TreeNode[], str: string[]) {
        for (let i = 0; i < nodes.length; i++) {
            if (!nodes[i].leaf && nodes[i].children[0].leaf) {
                for (let j = 0; j < nodes[i].children.length; j++) {
                    if (str.includes(nodes[i].children[j].data)) {
                        if (!this.permissionsNodesSelected.includes(nodes[i].children[j])) {
                            this.permissionsNodesSelected.push(nodes[i].children[j]);
                        }
                    }
                }
            }
            if (nodes[i].leaf) {
                return;
            }
            this.checkNode(nodes[i].children, str);
            let lengthChildren = nodes[i].children.length;
            let countPartial = 0;
            for (let j = 0; j < nodes[i].children.length; j++) {
                if (this.permissionsNodesSelected.includes(nodes[i].children[j])) {
                    countPartial++;
                }
                if (nodes[i].children[j].partialSelected) nodes[i].partialSelected = true;
            }
            if (countPartial == 0) { }
            else if (countPartial == lengthChildren) {
                nodes[i].partialSelected = false;
                if (!this.permissionsNodesSelected.includes(nodes[i])) {
                    this.permissionsNodesSelected.push(nodes[i]);
                }
            }
            else {
                nodes[i].partialSelected = true;
            }
        }
    }

    public resetDropdowns($event)
    {
        if($event != undefined && $event.value == 1)
        {
            if(this.user_new != undefined && this.user_new != null)
                this.user_new.customerId = null;
            if(this.user_edit != undefined && this.user_edit != null)
                this.user_edit.customerId = null;
        }
        else if($event != undefined && $event.value == 2)
        {
            if(this.user_new != undefined && this.user_new != null)
                this.user_new.supplierId = null;
            if(this.user_edit != undefined && this.user_edit != null)
                this.user_edit.supplierId = null;
        }
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    closePermissionUser() {
        this.permissionModal = false;
        this.permissionsNodes = [] as TreeNode[];
        this.permissionsNodesSelected = [] as TreeNode[];
        this.permissionsNodesUnselected = [] as TreeNode[];
    }

    setRoleToSelect()
    {
        for(let element in RoleToSelect)
        {
            if(isNaN(Number(element)))
            {
                this.roleToSelect.push(
                    {
                        id: Number.parseInt(RoleToSelect[element]),
                        label: this.translate.translateRoleToSelect(Number(RoleToSelect[element]))
                    }
                )
            }
        }
    }

    setCustomersDropdown()
    {
        this.customerService.getCustomerList(this.token, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.customerList = res.data.map((customer) => {
                            return {
                                id: customer.id,
                                companyName: customer.companyName
                            }
                        });
                    }
                }
            ),
            catchError((res) => {
                return of()
            })
        ).subscribe();
    }

    setSuppliersDropdown()
    {
        this.supplierService.getSupplierList(this.token, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.supplierList = res.data.map((supplier) => {
                            return {
                                id: supplier.id,
                                companyName: supplier.companyName
                            }
                        });
                    }
                }
            ),
            catchError((res) => {
                return of()
            })
        ).subscribe();
    }

    setServiceInstancesDropdown() {
        this.serviceInstanceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    setGroupDropdown() {
        this.groupService.getGroupsList(this.token, null, null)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.groupList = res.data.map((group) => {
                            return {
                                id: group.id,
                                name: group.name
                            }
                        })
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    onChangeServiceInstance($event) {
        if ($event.value != null && $event.value != "") {
            this.disable_group = false;
            this.setGroupDropdownByServiceInstance($event.value);
        }
    }

    setGroupDropdownByServiceInstance(serviceInstanceId: string) {
        this.groupService.getGroupsList(this.token, null, serviceInstanceId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.groupList = res.data.map((group) => {
                            return {
                                id: group.id,
                                name: group.name
                            }
                        })
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    permissionsUser(id: string) {
        this.permissionModal = true;
        this.permissionsNodesSelected = [] as TreeNode[];
        this.permissionsNodesUnselected = [] as TreeNode[];
        this.getPermissionsList();
        this.getPermissionsUser(id);
        this.userPermissionId = id;
    }

    getPermissionsList() {
        this.permissionService.getListPermission(this.token)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.permissionsList = res.data.map((permission) => {
                            return {
                                module: permission.module,
                                action: permission.actions
                            }
                        });

                        let jsonModel = this.treenodes.transformData(this.permissionsList);

                        this.permissionsNodes = <TreeNode[]>JSON.parse(jsonModel);
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    getPermissionsUser(id: string) {
        this.permissionService.getListUserPermissions(this.token, id)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.permissionsUserList = res.data.map((permission) => {
                            return {
                                module: permission.permissionData.module,
                                action: permission.permissionData.action
                            }
                        });
                        this.permissionstrings = [] as string[];
                        this.permissionsUserList.forEach(perm => {
                            this.permissionstrings.push(perm.action.toString());
                        });
                    }
                }),
                catchError(() => {
                    return of();
                }),
                finalize(() => {
                    this.checkNode(this.permissionsNodes, this.permissionstrings);
                })
            )
            .subscribe();
    }

    selectNode($event) {
        if (this.permissionsNodesUnselected.length > 0) {
            let i = 0;
            this.permissionsNodesUnselected.forEach(node => {
                if (node.data == $event.node.data) {
                    this.permissionsNodesUnselected.splice(i, 1);
                    return;
                }
                i++;
            });
        }
    }

    unselectNode($event) {
        let context = this;
        if (typeof $event.node !== "undefined" && typeof $event.node.children !== "undefined" && $event.node.children.length > 0) {
            $event.node.children.map((permNode) => {
                context.permissionsNodesUnselected.push(permNode);
            });
        } else {
            context.permissionsNodesUnselected.push($event.node);
        }
    }

    savePermissions() {
        let selected = [] as string[];
        let unselected = [] as string[];
        let message = undefined;
        let success = false;
        let context = this;

        if (context.permissionsNodesSelected.length > 0) {
            context.permissionsNodesSelected.forEach((node) => {
              if (node.leaf == true) {
                selected.push(node.data);
              }
            });

            if (selected.length == 0) {
                context.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Select at least one permission",
                });
                return of();
            }

            context.permissionService.setPermissionsCodeUser(context.token, context.userPermissionId, selected)
                .pipe(
                    tap((res) => {
                        success = res.data;
                        message = res.message;
                    }),
                    catchError((res) => {
                        context.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        });
                        return of();
                    }),
                    finalize(() => {
                        if (success) {
                            if (context.permissionsNodesUnselected.length > 0) {
                                context.permissionsNodesUnselected.forEach(
                                (node) => {
                                    if (
                                    node.leaf == true &&
                                    !selected.includes(node.data)
                                    ) {
                                    unselected.push(node.data);
                                    }
                                }
                                );

                                context.permissionService
                                .unsetPermissionsCodeUser(
                                    this.token,
                                    this.userPermissionId,
                                    unselected
                                )
                                .pipe(
                                    tap((res) => {
                                        if (res.data != null) {
                                            if ((success = true)) {
                                                this.messageService.add({
                                                    severity: "success",
                                                    summary: "Success",
                                                    detail: res.message ?? "Success",
                                                });
                                            }
                                        }
                                    }),
                                    catchError((res) => {
                                        this.messageService.add({
                                            severity: "error",
                                            summary: "Error",
                                            detail:
                                            res.error?.message ?? res.error.title,
                                        });
                                        return of();
                                    }),
                                    finalize(() => {
                                        if (success) {
                                            context.closePermissionUser();
                                        }                                        
                                    })
                                )
                                .subscribe();
                            } else {
                                context.messageService.add({
                                    severity: "success",
                                    summary: "Success",
                                    detail: message ?? "Success",
                                });
                                context.closePermissionUser();
                            }
                        }
                    })
                )
                .subscribe();
        } else {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Select at least one permission",
            });
            return of();
        }
    }

    getList(event: LazyLoadEvent) {
        if (event != null)
            this.event_lazy_local = event;

        this.usersListLoading = true;
        this.userService.getUsersList(this.token, this.event_lazy_local)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        let final = res.data.slice(this.event_lazy_local.first, (this.event_lazy_local.first + this.event_lazy_local.rows))
                        this.totalRecords = res.data.length;
                        this.usersList = final.map((user) => {
                            return {
                                id: user.id,
                                name: user.name,
                                surname: user.surname,
                                email: user.email,
                                username: user.username
                            }
                        });
                    }

                }),
                catchError((res) => {
                    return of();
                }),
                finalize(() => {
                    this.usersListLoading = false;
                })
            )
            .subscribe();
    }

    test($event)
    {
        console.log($event);
    }

    createUser() {
        this.userCreateModal = true;
        this.user_new = {} as User;
        this.disable_group = true;
    }

    closeCreateUserModal() {
        this.userCreateModal = false;
        this.createUserForm.resetForm();
        this.user_new = {} as User;
        this.disable_group = true;
    }

    saveUser() {
        this.userService.createUser(this.token, this.user_new)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.message ?? 'Success'
                    });
                    this.closeCreateUserModal();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    editUser(id: string) {
        this.userService.getUser(this.token, id)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.setGroupDropdownByServiceInstance(res.data.serviceInstanceId);

                        this.user_edit = {
                            id: res.data.id,
                            name: res.data.name,
                            username: res.data.username,
                            email: res.data.email,
                            surname: res.data.surname,
                            password: res.data.password,
                            confirmPassword: res.data.password,
                            serviceInstanceId: res.data.serviceInstanceId,
                            userGroupId: res.data.usersGroupId,
                            customerId: res.data.customerId ?? null,
                            supplierId: res.data.supplierId ?? null
                        }

                        if(this.user_edit.customerId != null)
                        {   
                            this.roleselected = 1;
                        }
                        else if(this.user_edit.supplierId != null)
                        {
                            this.roleselected = 2;
                        }
                        else
                        {
                            this.roleselected = null;
                        }

                        this.userEditModal = true;
                        this.disable_group = false;
                    }
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    this.userEditModal = false;
                    return of();
                }),
                finalize(() => { })
            )
            .subscribe();
    }

    closeEditUserModal() {
        this.userEditModal = false;
        this.user_edit = {} as User;
        this.editUserForm.resetForm();
        this.disable_group = true;
    }

    updateUser() {
        if(typeof this.user_edit.confirmPassword === 'undefined'){
            this.user_edit.confirmPassword = null;
        }
        if(typeof this.user_edit.password === 'undefined'){
            this.user_edit.password = null;
        }
        this.userService.updateUser(this.token, this.user_edit)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.message ?? 'Success'
                    });
                    this.closeEditUserModal();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    deleteUser(id: string, username: string) {
        this.confirmationService.confirm({
            message: "You are about to delete '" + username + "'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                this.userService.deleteUser(this.token, id)
                    .pipe(
                        tap((res) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: res.message ?? "Success"
                            });
                            this.getList(null);
                        }),
                        catchError((res) => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                        })
                    )
                    .subscribe();
            },
            reject: () => {
                return;
            },
            rejectVisible: true
        });
    }
}